import React from 'react'
import './Home.css';
import { Link } from "react-router-dom";
import Admin from './Admin';
function Home() {



  return (
    
    <>
 <a href='https://gphone168.shop/'>
 <Admin />
 </a>
<nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">

        
        <div className="container-fluid">
            <a className="navbar-brand" href="https://page.line.me/rsmall?openQrModal=true"><img src="logo.png" alt="logo" width="100" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars ms-1"></i>
                </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
             
                    <li className="nav-item"><a className="nav-link" href="https://page.line.me/rsmall?openQrModal=true">  <Link to="/">หน้าหลัก</Link></a></li>
                    <li className="nav-item"><a className="nav-link" href="https://page.line.me/rsmall?openQrModal=true">Portfolio</a></li>
                    <li className="nav-item"><a className="nav-link" href="https://page.line.me/rsmall?openQrModal=true">About</a></li>
                    <li className="nav-item"><p type="button" className="nav-link" id="admin">  <Link to="/admin">แอดมิน</Link></p></li>
                    <li className="nav-item"><a className="nav-link" href="https://page.line.me/rsmall?openQrModal=true">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
 
    <header className="masthead">
        <div className="container">
            <div className="masthead-subheading">PG Phone โทรศัพท์มือถือ ราคาถูกที่สุด!</div>
            <div className="masthead-heading text-uppercase">PG Phone เว็บตรง จากผู้ผลิต </div>
            <a className="btn btn-primary btn-xl text-uppercase" href="https://page.line.me/rsmall?openQrModal=true">PG เว็บตรง บริการเร็วที่สุด</a>
        </div>
    </header>


  



    
    <section className="page-section" id="services">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">PG เว็บตรง ช่วงโปรโมชั่นดีดี</h2>
                <h3 className="section-subheading text-muted">PG เว็บตรง จากผู้ผลิต PG Phone โทรศัพท์มือถือ ราคาถูกที่สุด</h3>
            </div>
            <div className="row text-center">
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
                        </span>
                    <h4 className="my-3">PG เว็บตรง เว็บไซต์ ที่ไห้บริการ มือถือ PG</h4>
                    <p className="text-muted">(สินค้าใหม่) สมาร์ทโฟนใหม่ล่าสุด PG รุ่น R1 A หน้าจอกว้าง 7.2 นิ้ว เต็มจอ!!! รองรับเป๋าตัง ธนาคารเครื่องใหญ่เล่มเกมสะใจ</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                    <h4 className="my-3">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h4>
                    <p className="text-muted">โทรศัพท์มือถือ PG รุ่น R1 PRO สมาร์ทโฟน 4G หน้าจอกว้าง 6 นิ้ว รองรับ 2 ซิม สมาร์ทโฟน 2 ซิม ราคาถูก ใช้ได้ทั้ง 3G/4G ถ่ายรูปสวย เล่นเกมส์ลื่นไม่มีสะดุด แบตอึดทน ไม่ต้องชาร์จบ่อย

                    </p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
                        </span>
                    <h4 className="my-3">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h4>
                    <p className="text-muted">หน้าจอ ความกว้างหน้าจอ 6 นิ้ว ความละเอียดหน้าจอ HD 480x960pixels กล้อง กล้องหน้า 8 ล้านพิกเซล กล้องหลัง 13 ล้านพิกเซล มีโหมด Face Beauty ถ่ายภาพหน้าชัดหลังเบลอได้ รองรับ 2 ซิม (Micro Sim)</p>
                </div>
            </div>
        </div>
    </section>
  
    <section className="page-section bg-light" id="portfolio">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h2>
                <h3 className="section-subheading text-muted">รายละเอียดสินค้า <br/>
                    <iframe width="300" height="200" src="https://www.youtube.com/embed/2ufW1D5PsH0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br/> หน้าจอ ความกว้างหน้าจอ 6 นิ้ว ความละเอียดหน้าจอ HD 480x960pixels กล้อง กล้องหน้า 8 ล้านพิกเซล กล้องหลัง 13 ล้านพิกเซล มีโหมด Face Beauty ถ่ายภาพหน้าชัดหลังเบลอได้ รองรับ 2 ซิม (Micro Sim) 3G 4G การใช้งานอื่นๆ รองรับการปลดล็อคหน้าจอด้วยระบบสแกนใบหน้า
                    ระบบปฏิบัติการแอนดรอยด์ 9.0 หน่วยความจำเครื่อง RAM 3 ROM 32 ระบบประมวลผล CPU MTK 6739 1.3 GHz แบตเตอรี่ 3000mAh การรับประกันสินค้า : 1 ปี (เงื่อนไขเป็นไปตามบริษัทกำหนด) อุปกรณ์ภายในกล่อง สมาร์ทโฟน PG รุ่น R1 PRO : 1 เครื่อง ฟิล์มใส
                    : 2 แผ่น (ติดมากับตัวเครื่อง 1 แผ่น แยก 1 แผ่น) ฟิล์มกระจก : 1 แผ่น หูฟัง : 1 ชิ้น โทรศัพท์สมาร์ทโฟน PG รุ่น R1 PRO มีให้เลือกทั้งหมด 3 สี PG รุ่น R1 PRO สีทอง PG รุ่น R1 PRO สีแดง PG รุ่น R1 PRO สีเขียว สินค้า 1 ชุด ประกอบไปด้วย 1.สมาร์ทโฟน
                    PG รุ่น R1 PRO (พร้อมอุปกรณ์ในกล่อง) : 1 เครื่อง 2. สินค้าแถมฟรี ลำโพงบลูทูธ (คละสี) : 1 ชิ้น ราคาสินค้าทั้งหมด (รวมมูลค่าของแถม) : 5,280 บาท</h3>
            </div>
            <div className="row">
                <div className="col-lg-4 col-sm-6 mb-4">
                  
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/8017_26-01-2022-17-32-54_opfkp.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</div>
                            <div className="portfolio-caption-subheading text-muted">PG รุ่น R1 PRO สีทอง</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/8048_26-01-2022-17-32-54_lqepl.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</div>
                            <div className="portfolio-caption-subheading text-muted">PG รุ่น R1 PRO สีเขียว</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/5133_26-01-2022-17-32-54_bxwkt.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</div>
                            <div className="portfolio-caption-subheading text-muted">PG รุ่น R1 PRO สีแดง</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/9677_26-01-2022-17-32-55_quriw.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">สมาร์ทโฟน PG รุ่น R1 PRO (พร้อมอุปกรณ์ในกล่อง) : 1 เครื่อง</div>
                            <div className="portfolio-caption-subheading text-muted">สินค้าแถมฟรี ลำโพงบลูทูธ (คละสี) : 1 ชิ้น ราคาสินค้าทั้งหมด (รวมมูลค่าของแถม) : 5,280 บาท

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/8604_26-01-2022-17-32-55_czcsr.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">การใช้งานอื่นๆ
                            </div>
                            <div className="portfolio-caption-subheading text-muted">รองรับการปลดล็อคหน้าจอด้วยระบบสแกนใบหน้า ระบบปฏิบัติการแอนดรอยด์ 9.0 หน่วยความจำเครื่อง RAM 3 ROM 32
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href="https://www.rsmall.com/product/pg-smarthphone-r-pro-1-gold">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="https://control.starzth.com/assets/images/product/1113_26-01-2022-17-32-55_lbucm.jpeg" alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">รองรับ 2 ซิม (Micro Sim) 3G 4G
                            </div>
                            <div className="portfolio-caption-subheading text-muted">กล้องหน้า 8 ล้านพิกเซล กล้องหลัง 13 ล้านพิกเซล มีโหมด Face Beauty</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="page-section" id="about">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase"> PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h2>
                <h3 className="section-subheading text-muted">PG เว็บตรง เรามีโปรโมชั่นมากมาย</h3>
            </div>
            <ul className="timeline">
                <li>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="https://control.starzth.com/assets/images/product/6104_26-01-2022-17-32-55_oinuk.jpeg" alt="..." /></div>
                    <div className="timeline-panel">
                        <div className="timeline-heading">
                            <h4>หน้าจอ
                            </h4>
                            <h4 className="subheading">ความกว้างหน้าจอ 6 นิ้ว
                            </h4>
                        </div>
                        <div className="timeline-body">
                            <p className="text-muted">กล้องหลัง 13 ล้านพิกเซล มีโหมด Face Beauty <br/> ถ่ายภาพหน้าชัดหลังเบลอได้
                            </p>
                        </div>
                    </div>
                </li>
                <li className="timeline-inverted">
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="https://control.starzth.com/assets/images/product/8423_26-01-2022-17-32-56_awkeo.jpeg" alt="..." /></div>
                    <div className="timeline-panel">
                        <div className="timeline-heading">
                            <h4>อุปกรณ์ภายในกล่อง

                            </h4>
                            <h4 className="subheading">อุปกรณ์ภายในกล่อง

                            </h4>
                        </div>
                        <div className="timeline-body">
                            <p className="text-muted">สมาร์ทโฟน PG รุ่น R1 PRO : 1 เครื่อง
                                <br/> ฟิล์มใส : 2 แผ่น (ติดมากับตัวเครื่อง 1 แผ่น แยก 1 แผ่น) ฟิล์มกระจก : 1 แผ่น หูฟัง : 1 ชิ้น </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="https://control.starzth.com/assets/images/product/4861_26-01-2022-17-32-56_xhgxu.jpeg" alt="..." /></div>
                    <div className="timeline-panel">
                        <div className="timeline-heading">
                            <h4>โทรศัพท์สมาร์ทโฟน PG รุ่น R1 PRO มีให้เลือกทั้งหมด 3 สี</h4>
                            <h4 className="subheading">PG เว็บตรง เล่นง่าย ไม่เสียความรู้สึก</h4>
                        </div>
                        <div className="timeline-body">
                            <p className="text-muted">PG รุ่น R1 PRO สีทอง PG รุ่น R1 PRO สีแดง PG รุ่น R1 PRO สีเขียว</p>
                        </div>
                    </div>
                </li>
                <li className="timeline-inverted">
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="https://control.starzth.com/assets/images/product/6353_26-01-2022-17-32-56_bpqbg.jpeg" alt="..." /></div>
                    <div className="timeline-panel">
                        <div className="timeline-heading">
                            <h4>สินค้า 1 ชุด ประกอบไปด้วย

                            </h4>
                            <h4 className="subheading">สินค้าแถมฟรี

                            </h4>
                        </div>
                        <div className="timeline-body">
                            <p className="text-muted">สมาร์ทโฟน PG รุ่น R1 PRO (พร้อมอุปกรณ์ในกล่อง) : 1 เครื่อง. สินค้าแถมฟรี ลำโพงบลูทูธ (คละสี) : 1 ชิ้น ราคาสินค้าทั้งหมด (รวมมูลค่าของแถม) : 5,280 บาท

                            </p>
                        </div>
                    </div>
                </li>
                <li className="timeline-inverted">
                    <div className="timeline-image">
                        <h4>
                            PG เว็บตรง
                            <br /> ยินดีไห้บริการ
                            <br /> ลูกค้าทุกท่าน
                        </h4>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <section className="page-section bg-light" id="team">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase"> PG R1plus </h2>
                <h3 className="section-subheading text-muted">โทรศัพท์มือถือราคาถูก (สินค้าใหม่) สมาร์ทโฟน PG R1plus ใช้ได้ 2ซิม 4G มาพร้อมกับหน้าจอ6.6นิ้ว Ram2 Rom16 กับกล้องหน้าและหลัง 8MP/13MP</h3>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle" src="https://control.starzth.com/assets/images/product/5774_26-01-2022-17-32-55_rixrm.jpeg" alt="..." />
                        <h4> PG เว็บตรง</h4>
                        <p className="text-muted">บริการเร็วที่สุด</p>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Twitter Profile"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand Facebook Profile"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Parveen Anand LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle" src="https://control.starzth.com/assets/images/product/1113_26-01-2022-17-32-55_lbucm.jpeg" alt="..." />
                        <h4> PG เว็บตรง</h4>
                        <p className="text-muted">โแรโมชั่นเพียบ</p>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Diana Petersen Twitter Profile"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Diana Petersen Facebook Profile"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Diana Petersen LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle" src="https://control.starzth.com/assets/images/product/8017_26-01-2022-17-32-54_opfkp.jpeg" alt="..." />
                        <h4> PG เว็บตรง</h4>
                        <p className="text-muted">เล่นแล้วจะติดใจ</p>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Twitter Profile"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                    <p className="large text-muted">รายละเอียดสินค้า โทรศัพท์มือถือราคาถูก (สินค้าใหม่) สมาร์ทโฟน PG R1plus ใช้ได้ 2ซิม 4G มาพร้อมกับหน้าจอ6.6นิ้ว Ram2 Rom16 กับกล้องหน้าและหลัง 8MP/13MP สินค้ามีประกัน 12 เดือนหากสินค้ามีปัญหาส่งเคลมฟรี

                    </p>
                </div>
            </div>
        </div>
    </section>
    <div className="py-5">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-3 col-sm-6 my-3">
                    <a href="https://page.line.me/rsmall?openQrModal=true"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/microsoft.svg" alt="..." aria-label="Microsoft Logo" /></a>
                </div>
                <div className="col-md-3 col-sm-6 my-3">
                    <a href="https://page.line.me/rsmall?openQrModal=true"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/google.svg" alt="..." aria-label="Google Logo" /></a>
                </div>
                <div className="col-md-3 col-sm-6 my-3">
                    <a href="https://page.line.me/rsmall?openQrModal=true"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/facebook.svg" alt="..." aria-label="Facebook Logo" /></a>
                </div>
                <div className="col-md-3 col-sm-6 my-3">
                    <a href="https://page.line.me/rsmall?openQrModal=true"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/ibm.svg" alt="..." aria-label="IBM Logo" /></a>
                </div>
            </div>
        </div>
    </div>
    <section className="page-section" id="contact">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h2>
                <h3 className="section-subheading text-muted">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</h3>
            </div>
          
            <form id="contactForm" data-sb-form-api-token="API_TOKEN" action="member.php" method="post">
                <div className="row align-items-stretch mb-5">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input className="form-control" name="name" type="text" placeholder="Your Name *" data-sb-validations="required" />
                            <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                        </div>
                        <div className="form-group">
                            <input className="form-control" name="email" type="email" placeholder="Your Email *" data-sb-validations="required,email" />
                            <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                            <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                        </div>
                        <div className="form-group mb-md-0">
                            <input className="form-control" name="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" />
                            <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-textarea mb-md-0">
                            <textarea className="form-control" name="message" placeholder="Your Message *" data-sb-validations="required"></textarea>
                            <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                        </div>
                    </div>
                </div>
  
                <div className="d-none" id="submitSuccessMessage">
                    <div className="text-center text-white mb-3">
                        <div className="fw-bolder">Form submission successful!</div>
                        To activate this form, sign up at
                        <br />
                        <a href="https://page.line.me/rsmall?openQrModal=true">https://page.line.me/rsmall?openQrModal=true</a>
                    </div>
                </div>
             
                <div className="d-none" id="submitErrorMessage">
                    <div className="text-center text-danger mb-3">Error sending message!</div>
                </div>
              
                <div className="text-center"> <button className="btn btn-primary " name="submit" type="submit">Send Message</button></div>
            </form>
        </div>
    </section>
   
    <footer className="footer py-4">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-4 text-lg-start">PG เว็บตรง เว็บไซต์ จากสิงค์โปร &copy; </div>
                <div className="col-lg-4 my-3 my-lg-0">
                    <a className="btn btn-dark btn-social mx-2" href="https://page.line.me/rsmall?openQrModal=true" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-dark btn-social mx-2" href="https://page.line.me/rsmall?openQrModal=true" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-dark btn-social mx-2" href="https://page.line.me/rsmall?openQrModal=true" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                </div>
                <div className="col-lg-4 text-lg-end">
                    <a className="link-dark text-decoration-none me-3" href="https://page.line.me/rsmall?openQrModal=true">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</a>
                    <a className="link-dark text-decoration-none" href="https://page.line.me/rsmall?openQrModal=true">PG เว็บตรง เว็บไซต์ จากสิงค์โปร</a>
                </div>
            </div>
        </div>
    </footer>
  
    </>
  );
}

export default Home;
