import React from 'react';
import User from './User';
import './Home.css';
import Swal from 'sweetalert2';

class App extends React.Component {
  constructor(props) {
    super(props);
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    this.state = { userId: '', password: '', isAuthenticated };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.userId === 'admin' && this.state.password === '1122') {
      this.setState({ isAuthenticated: true });
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      Swal.fire({
        title: 'เฉพาะ Admin เท่านั้น',
        text: 'กรุณา ใส่ข้อมูล admin ที่ถูกต้อง.',
        imageUrl: 'logo.png',
        imageWidth: 200,
        imageHeight: 100,
        imageAlt: 'Custom image',
      });

    }
  }

  handleLogout() {
    this.setState({ isAuthenticated: false });
    localStorage.removeItem('isAuthenticated');
  }

  render() {
    if (this.state.isAuthenticated) {
        
      
      
      

      return (
       <>
       
        <User />
       
       
       
       </>
      );
    }

    return (
     <> <div className="Appfrom shadow-lg p-3 mb-5 bg-body-tertiary rounded">
     <form onSubmit={this.handleSubmit}>
       <label>
         User ID:
         <input type="text" name="userId" class="form-control" placeholder="User Admin" value={this.state.userId} onChange={this.handleChange} />
       </label>
       <br />
       <label>
         Password:
         <input type="password" name="password" class="form-control" placeholder='Password' value={this.state.password} onChange={this.handleChange} />
       </label>
       <br />
       <input type="submit" value="Login" class="btn btn-primary"/>
     </form>
   </div>
   </>
    );
  }
}

export default App;
