import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './Home/Home';
import Adminit from './Home/Adminit';
import Ip from './Ip';


function App() {

  return (
    <BrowserRouter>
      <div id='img'>

        <Routes>
        <Route path="/" element={<Home />} />

          <Route path="/admin" element={<Adminit />} />
        </Routes>
      </div>
   
      <Ip/>

    </BrowserRouter>
  );
}

export default App;
