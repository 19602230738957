import React, { useState, useEffect } from 'react';
import Imge from './Imge';
import Imges from './Imges';

function Class() {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [userIP, setUserIP] = useState('');

  useEffect(() => {
    const fetchIPAndAuthorization = () => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          const ipAddress = data.ip;
          setUserIP(ipAddress);
  
          return fetch(`https://api.lcsbf.com/check-ip?ipAddress=${ipAddress}`);
        })
        .then(response => response.json())
        .then(data => {
          if (data.message === "อนุญาต") {
              setIsAuthorized(true);
          } else {
              setIsAuthorized(false);
          }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.message);
            setIsAuthorized(false);
        });
    };

    // Fetch IP and authorization when component mounts
    fetchIPAndAuthorization();

    // Set an interval to fetch IP and authorization every 5 seconds
    const intervalId = setInterval(fetchIPAndAuthorization, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  if (isAuthorized === null) {
    return <div>กำลังตรวจสอบ...</div>;
  }

  console.log(userIP);
  if (!isAuthorized) {
     return <div> 🔴<div id='p'><Imges/></div> {userIP} </div>;
  }

  return (
    <div>
      <> 🟢 </>  
      <Imge />
    </div>
  );
}

export default Class;
